import clsx from 'clsx';
import React, { ChangeEvent, forwardRef, useState } from 'react';

type InputProps = {
  options: string[];

  labelText: string;
  labelHidden?: boolean;

  helperText?: string;

  error?: boolean;
};

const Select = forwardRef(
  (
    {
      id,
      options,
      required = false,
      disabled = false,
      labelText,
      labelHidden = false,
      helperText,
      error = false,
      ...props
    }: InputProps & React.ComponentProps<'input'>,
    ref
  ) => {
    const [isValid, setIsValid] = useState(false);
    const onChange = (event: ChangeEvent) => {
      setIsValid(!!event.target?.value);
    };

    return (
      <div>
        <label htmlFor={id} className="block">
          {labelText && (
            <span
              className={clsx('mb-1 block text-sm font-semibold', {
                'sr-only': labelHidden,
                'text-gray-900': !error,
                'text-red-500': error
              })}
            >
              {labelText}
              {required && <span className="text-red-700"> *</span>}
            </span>
          )}

          <div className="relative">
            <select
              ref={ref}
              id={id}
              required={required}
              disabled={disabled}
              {...props}
              onChange={onChange}
              className={clsx('block w-full rounded-md shadow-sm focus:ring focus:ring-opacity-50 disabled:opacity-50', {
                'border-gray-300 focus:border-primary-300 focus:ring-primary-200': !error,
                'border-red-500 focus:border-red-300 focus:ring-red-200': error,
                'text-gray-500': !isValid
              })}
            >
              <option value="0" className="hidden">
                {labelText}...
              </option>
              {options.map(option => (
                <option key={option} className="text-gray-900">
                  {option}
                </option>
              ))}
            </select>
          </div>
        </label>
        <span
          className={clsx('mr-2 block h-5 text-right text-sm font-medium', {
            'text-gray-600': !error,
            'text-red-500': error,
            'mb-6': (helperText || '').length > 50
          })}
        >
          {helperText || ''}
        </span>
      </div>
    );
  }
);

Select.displayName = 'Select';

export default Select;
